import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../../_metronic/layout/core'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../../_metronic/assets/ts/components'
import { KTCard, KTCardBody, KTIcon, Loader, enumPolicies } from '../../../../_metronic/helpers'
import { PRIMARY_COLOR } from '../../../../_metronic/helpers/colorConstant';
import Swal from 'sweetalert2'
import PolicyChecker from '../../../../_metronic/helpers/components/PolicyChecker';

import { clearState, getProductList, changeStatus, cloneProduct } from './productActions';

const Products = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        productList,
        loading,
        success
    } = props;

    //actions
    const {
        clearState,
        getProductList,
        changeStatus,
        cloneProduct
    } = props;


    const [keyword, setKeyword] = useState();
    const [sorting, setSorting] = useState({ name: "", sku: "" })
    const [sortedList, setSortedList] = useState([]);

    useEffect(() => {

        getProductList();

        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        setTimeout(() => { 
            MenuComponent.reinitialization();
        }, 100)
        setSortedList(productList);
    }, [productList])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])


    useEffect(() => {
        if (keyword != undefined) {
            const timeOutId = setTimeout(() => getProductList(keyword), 400);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const breadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CATALOG.MANAGEMENT.TITLE' }),
            path: '/catalog-management/products',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onSearch = (value) => {
        setKeyword(value);
    }

    const onStatusChange = (id, isActive) => {
        changeStatus(id, isActive);
    }

    const onClone = (id) => {

        Swal.fire({
            title: "Are you sure?",
            text: "Do you want to clone of this product?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            cancelButtonColor: "#dbdfe9",
            confirmButtonText: "Yes"
        }).then((result) => {
            if (result.isConfirmed) {
                cloneProduct(id)
            }
        });
    }

    const renderProductImage = (item) => {
        if (item && item.imageLink) {
            return (
                <div className="symbol symbol-square symbol-75px">
                    <img src={item.imageLink} alt={item.name} className="h-50" />
                </div>
            )
        }

        return (
            <div className="symbol symbol-square symbol-75px">
                <KTIcon iconName='parcel-tracking' className='fs-5x' />
            </div>
        )
    }

    const onChangeSorting = (field) => {
        var list = sortedList;
        if (field == "name") {
            var sort = sorting["name"];
            if (sort == "table-sort-asc") {
                list = sortedList.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    return 0;
                });
                setSorting({ name: "table-sort-desc" })
            } else {
                list = sortedList.sort(function (a, b) {
                    if (a.name > b.name) {
                        return -1;
                    }
                    return 0;
                });
                setSorting({ name: "table-sort-asc" })
            }

            setSortedList(list);
        } else if (field == "sku") {
            var sort = sorting["sku"];
            if (sort == "table-sort-asc") {
                setSorting({ sku: "table-sort-desc" })
                list = sortedList.sort((a, b) => a.sku - b.sku)
            } else {
                setSorting({ sku: "table-sort-asc" })
                list = sortedList.sort((a, b) => b.sku - a.sku)
            }

            setSortedList(list);
        }
    }

    return (
        <>

            <PageTitle breadcrumbs={breadcrumbs} >{intl.formatMessage({ id: 'PRODUCT.LIST.BREADCRUMB' })}</PageTitle>

            <KTCard>

                <div className="card-header border-0 pt-6">
                    <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'PRODUCT.LIST.SEARCH.PRODUCT' })} value={keyword} onChange={(e) => onSearch(e.target.value)} />
                        </div>
                    </div>
                    
                    <PolicyChecker policies={[enumPolicies.ProductWrite]}>
                        <div className="card-toolbar">
                            <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                <Link to={`/catalog-management/products/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'PRODUCT.LIST.ADD.PRODUCT' })}</Link>
                            </div>
                        </div>
                    </PolicyChecker>

                </div>
                <KTCardBody className='table-responsive m-grid-responsive-md'>

                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer">
                        <thead>
                            <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                <th>#</th>
                                <th>{intl.formatMessage({ id: 'PRODUCT.IMAGE' })}</th>
                                <th className={sorting["name"]} onClick={() => onChangeSorting("name")}>{intl.formatMessage({ id: 'PRODUCT.NAME' })}</th>
                                <th className={sorting["sku"]} onClick={() => onChangeSorting("sku")}>{intl.formatMessage({ id: 'PRODUCT.SKU' })}</th>
                                <th>{intl.formatMessage({ id: 'PRODUCT.OPTION' })}</th>
                                <th>{intl.formatMessage({ id: 'PRODUCT.CATEGORY' })}</th>
                                <th>{intl.formatMessage({ id: 'PRODUCT.STATUS' })}</th>
                                <th>{intl.formatMessage({ id: 'PRODUCT.ACTIONS' })}</th>
                            </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bold'>
                            {
                                sortedList && sortedList.map((item, index) => {
                                    return (
                                        (
                                            <tr key={item.id}>
                                                <td>
                                                    {++index}
                                                </td>
                                                <td>
                                                    {renderProductImage(item)}
                                                </td>
                                                <td>
                                                    <Link to={`/catalog-management/products/${item.id}/detail`}>{item.name}</Link>
                                                </td>
                                                <td>{item.sku}</td>
                                                <td>{item.optionValueName}</td>
                                                <td>{item.categoryName}</td>
                                                <td>{item.isActive ? <div className="badge badge-light-success fw-bolder">{intl.formatMessage({ id: 'PRODUCT.ACTIVE.ENABLED' })}</div> : <div className="badge badge-light-danger">{intl.formatMessage({ id: 'PRODUCT.ACTIVE.LOCKED' })}</div>}</td>
                                                <td>
                                                    <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'PRODUCT.ACTIONS' })}
                                                        <KTIcon iconName='down' className='fs-5 m-0' />
                                                    </a>
                                                    <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                        <div className='menu-item px-3'>
                                                            <Link to={`/catalog-management/products/${item.id}`} className='menu-link px-3'> {intl.formatMessage({ id: 'PRODUCT.ACTIONS.EDIT' })} </Link>
                                                        </div>
                                                        <div className='menu-item px-3'>
                                                            {
                                                                item.isActive ?
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(item.id, false)}> {intl.formatMessage({ id: 'PRODUCT.ACTIONS.DEACTIVE' })} </a> :
                                                                    <a className='menu-link px-3' onClick={() => onStatusChange(item.id, true)}> {intl.formatMessage({ id: 'PRODUCT.ACTIONS.ACTIVE' })} </a>
                                                            }

                                                        </div>
                                                        <div className='menu-item px-3'>
                                                            <a className='menu-link px-3' onClick={() => onClone(item.id)}> {intl.formatMessage({ id: 'PRODUCT.ACTIONS.CLONE' })} </a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    )
                                })
                            }
                        </tbody>
                    </table>

                </KTCardBody>
            </KTCard >
            {loading && <Loader></Loader>}
        </>
    );
};



const mapStateToProps = state => {
    return {
        productList: state.Product.productList,
        loading: state.Product.loading,
        success: state.Product.success
    };
};

const mapActionsToProps = {
    clearState, getProductList, changeStatus, cloneProduct
}

export default connect(mapStateToProps, mapActionsToProps)(Products)