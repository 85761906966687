import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { toast } from 'react-toastify';
import { MenuComponent } from '../../../_metronic/assets/ts/components'
import { Loader } from '../../../_metronic/helpers'
import { Row, Col, Card, CardHeader, CardTitle, CardBody, CardFooter } from 'reactstrap';
import { Modal } from 'react-bootstrap'
import Moment from 'react-moment';
import moment from 'moment';
import Swal from 'sweetalert2'

import { DndContext, MouseSensor, useSensor, useSensors, TouchSensor, } from '@dnd-kit/core';
import Draggable from '../lead-management/Draggable';
import Droppable from '../lead-management/Droppable';


import { clearState, getCampaigns, getLeads, getLead, patchLead, getUserList, getHistory, postComment } from './leadActions';
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';

const Leads = (props) => {
    //inputs from url
    const params = useParams();
    const intl = useIntl()
    MenuComponent.reinitialization();

    //inputs from redux
    const {
        leads,
        lead,
        campaigns,
        users,
        historyList,
        loading,
        success,
        error
    } = props;

    //actions
    const {
        clearState,
        getLead,
        getLeads,
        getCampaigns,
        patchLead,
        getUserList,
        getHistory,
        postComment
    } = props;

    const [steps, setSteps] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [filteredList, setFilteredList] = useState([]);
    const [seeAllKeys, setSeeAllKeys] = useState([]);
    const [comment, setComment] = useState('');

    useEffect(() => {
        getCampaigns();
        getUserList();
        return () => {
            clearState()
        }
    }, []);


    useEffect(() => {
        MenuComponent.reinitialization();
        if (campaigns && campaigns.length > 0) {
            getLeads(campaigns[0].campaignType)
            setSteps(Object.entries(campaigns[0].stateMap))
        }
    }, [campaigns])

    useEffect(() => {
        if (leads && leads.length > 0) {

            for (let i = 0; i < steps.length; i++) {
                var items = leads.filter(s => s.status == steps[i][0]);
                items.map((item, index) => {
                    item.show = true;
                    if (index > 8 && !seeAllKeys.includes(item.status.toString()))
                        item.show = false;
                    return item;
                })
            }

            setFilteredList(leads);
        }
    }, [leads])

    useEffect(() => {
        if (success) {
            toast.success(intl.formatMessage({ id: success }));
        }
    }, [success])

    useEffect(() => {
        if (error) {
            toast.error(intl.formatMessage({ id: error }));
        }
    }, [error])

    useEffect(() => {
        if (lead) {
            setShowModal(true);
        }
    }, [lead])

    const onSearch = (value) => {
        const timeOutId = setTimeout(() => {
            var newList = leads.filter((item) => {
                return item.title.toLowerCase().includes(value.toLowerCase());
            });
            setFilteredList(newList);
        }, 400);
        return () => clearTimeout(timeOutId);
    }

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'LEAD.MANAGEMENT.TITLE' }),
            path: '/lead-management/users',
            isSeparator: false,
            isActive: false,
        },
        {
            title: '',
            path: '',
            isSeparator: true,
            isActive: false,
        }
    ]

    const onTabClick = (index, campaignType) => {
        setSteps(Object.entries(campaigns[index].stateMap))
        getLeads(campaignType)
    }

    const headerColorClass = (status) => {
        if (status == 1)
            return "bg-secondary";
        if (status == 2)
            return "bg-warning";
        if (status == 3)
            return "bg-success";
        if (status == 4)
            return "bg-danger";
        if (status == 5)
            return "bg-danger-active";

        return "bg-primary";
    }

    const renderRibbon = (item) => {
        if (item.nrOfComments == 0)
            return;

        return (
            <div className="ribbon-label bg-info">
                <i className="ki-duotone ki-messages fs-2" style={{ marginRight: "2px" }}>
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                </i>
                {item.nrOfComments}
            </div>
        )
    }

    const leadModal = (item) => {
        getLead(item.id)
        getHistory(item.id)
    }

    const onStatusChange = (value) => {
        if (value) {

            Swal.fire({
                title: intl.formatMessage({ id: "LEAD.QUESTION.SURE" }),
                text: intl.formatMessage({ id: "LEAD.QUESTION.STATUS" }),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: PRIMARY_COLOR,
                cancelButtonColor: "#dbdfe9",
                confirmButtonText: intl.formatMessage({ id: "GENERAL.YES" })
            }).then((result) => {
                if (result.isConfirmed) {
                    patchLead({ id: lead.id, status: value })
                } else {

                }
            });
        }
    }

    const onAssignChange = (value) => {
        if (value) {

            Swal.fire({
                title: intl.formatMessage({ id: "LEAD.QUESTION.SURE" }),
                text: intl.formatMessage({ id: "LEAD.QUESTION.ASSIGNEE" }),
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: PRIMARY_COLOR,
                cancelButtonColor: "#dbdfe9",
                confirmButtonText: intl.formatMessage({ id: "GENERAL.YES" })
            }).then((result) => {
                if (result.isConfirmed) {
                    patchLead({ id: lead.id, assignedBy: value })
                } else {

                }
            });
        }
    }

    const onSeeAllClick = (status) => {
        setFilteredList(prev => {
            return prev.map((item, index) => {
                if (item.status == status) {
                    item.show = true;
                }
                return item;
            })
        })
        setSeeAllKeys(prev => [...prev, status]);
    }

    const historyValue = (value) => {

        if (moment(value).isValid() && moment(value).year() > 2010) {
            return <Moment>{value}</Moment>
        }

        return value;
    }

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 10,
        },
    });
    const touchSensor = useSensor(TouchSensor, {
        activationConstraint: {
            delay: 400,
            tolerance: 5,
        },
    });

    const sensors = useSensors(
        mouseSensor,
        touchSensor
    );

    const handleDragEnd = (event) => {
        if (event.over.data.current != event.active.data.current.status) {
            patchLead({ id: event.active.data.current.id, status: event.over.data.current })
        }
    }

    const addComment = () => {
        if (comment == '') return;
        postComment({ id: lead.id, comment: comment })
        setComment('')
    }

    const renderModal = () => {
        if (!lead)
            return;

        return (
            <Modal size="xl" centered show={showModal} onHide={() => setShowModal(!showModal)}>
                <Modal.Header closeButton>
                    <Modal.Title>{lead.id} - {lead.firstName} {lead.lastName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <div className="table-responsive">
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                    <tbody className="fw-semibold text-gray-600">
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    <i className="ki-duotone ki-calendar fs-2 me-2">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>{intl.formatMessage({ id: "LEAD.FIELD.STATUS" })}</div>
                                            </td>
                                            <td className="fw-bold text-end">
                                                <select className='form-control form-select-sm form-select form-control-solid' onChange={(e) => onStatusChange(e.target.value)} value={lead.status}>
                                                    {steps.map(([key, value]) => {
                                                        return (
                                                            <option key={`status_dropdown_${value}`} value={key}>{value}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                        <Col>
                            <div className="table-responsive">
                                <table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
                                    <tbody className="fw-semibold text-gray-600">
                                        <tr>
                                            <td className="text-muted">
                                                <div className="d-flex align-items-center">
                                                    <i className="ki-duotone ki-user fs-2 me-2">
                                                        <span className="path1"></span>
                                                        <span className="path2"></span>
                                                    </i>{intl.formatMessage({ id: "LEAD.FIELD.ASSIGNEDBY" })}</div>
                                            </td>
                                            <td className="fw-bold text-end">
                                                <select className='form-control form-select-sm form-select form-control-solid' onChange={(e) => onAssignChange(e.target.value)} value={lead.assignedBy}>
                                                    <option value={0}>{intl.formatMessage({ id: "GENERAL.SELECT" })}</option>
                                                    {users && users.map((user, index) => {
                                                        return (

                                                            <option key={`user_dropdown_${index}`} value={user.id}>{user.name} {user.surname}</option>
                                                        )
                                                    })}
                                                </select>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-10'>
                        <Col>
                            <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link active`} data-bs-toggle="tab" href={`#kt_tab_pane_1`}>{intl.formatMessage({ id: "LEAD.FIELD.DETAILS" })} - ({lead.formFields && lead.formFields.length})</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link`} data-bs-toggle="tab" href={`#kt_tab_pane_2`}>{intl.formatMessage({ id: "LEAD.FIELD.COMMENTS" })} - ({lead.comments && lead.comments.length})</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className={`nav-link`} data-bs-toggle="tab" href={`#kt_tab_pane_3`}>{intl.formatMessage({ id: "LEAD.FIELD.HISTORY" })} - ({historyList && historyList.length})</a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                    <Row className='mt-10'>
                        <div className="tab-content" >

                            <div className="tab-pane fade show active" id="kt_tab_pane_1" role="tabpanel">
                                <Col>
                                    <Card>
                                        <CardBody style={{ height: "400px", overflowY: "auto" }}>
                                            <p className='mb-1'>
                                                <span className="text-gray-800 fw-bold fs-7 me-1" style={{ wordBreak: "break-word" }}> {intl.formatMessage({ id: "LEAD.FIELD.EMAIL" })}: </span>
                                                <span className="fs-7 text-danger" style={{ wordBreak: "break-word" }}>{lead.email}</span>
                                            </p>
                                            <p className='mb-1'>
                                                <span className="text-gray-800 fw-bold fs-7 me-1" style={{ wordBreak: "break-word" }}> {intl.formatMessage({ id: "LEAD.FIELD.PHONE" })}: </span>
                                                <span className="fs-7 text-danger" style={{ wordBreak: "break-word" }}>{lead.phone}</span>
                                            </p>
                                            <p className='mb-1'>
                                                <span className="text-gray-800 fw-bold fs-7 me-1" style={{ wordBreak: "break-word" }}> {intl.formatMessage({ id: "LEAD.FIELD.ADDRESS" })} - {intl.formatMessage({ id: "LEAD.FIELD.POSTCODE" })}: </span>
                                                <span className="fs-7 text-danger" style={{ wordBreak: "break-word" }}>{lead.streetAddress} - {lead.postCode}</span>
                                            </p>
                                            <p className='mb-1'>
                                                <span className="text-gray-800 fw-bold fs-7 me-1" style={{ wordBreak: "break-word" }}> {intl.formatMessage({ id: "LEAD.FIELD.DATE" })}: </span>
                                                <span className="fs-7 text-danger" style={{ wordBreak: "break-word" }}>{lead.createdOn}</span>
                                            </p>
                                            {lead.formFields && lead.formFields.map((field, index) => {
                                                return (
                                                    <p key={`lead_formfield_${index}`} className='mb-1'>
                                                        <span className="text-gray-800 fw-bold fs-7 me-1" style={{ wordBreak: "break-word" }}> {field.fieldKey}</span>
                                                        <span className="fs-7 text-danger" style={{ wordBreak: "break-word" }}>{field.fieldValue}</span>
                                                    </p>
                                                )
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>

                            <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                                <Col>
                                    <Card>
                                        <CardBody style={{ height: "282px", overflowY: "auto" }}>
                                            {lead.comments && lead.comments.map((field, index) => {
                                                return (
                                                    <div key={`lead_comments_${index}`}>
                                                        <div className="d-flex flex-stack">
                                                            <span className="text-gray-800 fw-bold fs-6 me-1" style={{ wordBreak: "break-word" }}>
                                                                <span style={{ fontWeight: "bolder", color: "gray" }}>{field.user && field.user.name} {field.user && field.user.surname}</span>
                                                                <br></br> <br></br>
                                                                {field.comment}
                                                            </span>
                                                            <span className="fw-bold fs-6 text-danger text-end">
                                                                <Moment format='ddd DD MMM yy'>{field.createdOn}</Moment>
                                                                <br></br>
                                                                <Moment format='HH:mm'>{field.createdOn}</Moment>
                                                            </span>
                                                        </div>
                                                        <div className="separator separator-dashed my-3"></div>
                                                    </div>
                                                )
                                            })}
                                        </CardBody>
                                        <CardFooter>
                                            <Row>
                                                <Col md={11}>
                                                    <textarea rows={2} className='form-control' onChange={(e) => setComment(e.target.value)} value={comment}></textarea>
                                                </Col>
                                                <Col md={1}>
                                                    <button className="btn btn-primary" onClick={() => addComment()} style={{ width: "100%", height: "100%" }}>
                                                        <i className="ki-duotone ki-send fs-2x"><span className="path1"></span><span className="path2"></span></i>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </div>

                            <div className="tab-pane fade" id="kt_tab_pane_3" role="tabpanel">
                                <Col>
                                    <Card>
                                        <CardBody style={{ height: "400px", overflowY: "auto" }}>
                                            <div className="table-responsive">
                                                <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
                                                    <thead>
                                                        <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                            <th className="min-w-100px">{intl.formatMessage({ id: "LEAD.FIELD.DATE" })}</th>
                                                            <th className="min-w-100px">{intl.formatMessage({ id: "LEAD.FIELD.USER" })}</th>
                                                            <th className="min-w-175px">{intl.formatMessage({ id: "LEAD.FIELD.COMMENT" })}</th>
                                                            <th className="min-w-70px">{intl.formatMessage({ id: "LEAD.FIELD.OLDVALUE" })}</th>
                                                            <th className="min-w-100px">{intl.formatMessage({ id: "LEAD.FIELD.NEWVALUE" })}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="fw-semibold text-gray-600">
                                                        {historyList && historyList.map((history, i) => {
                                                            return (
                                                                <tr key={`lead_history_${i}`}>
                                                                    <td><Moment>{history.date}</Moment></td>
                                                                    <td>{history.userName}</td>
                                                                    <td>{history.fieldName}</td>
                                                                    <td>
                                                                        <div className="badge badge-light-info">{historyValue(history.oldValue)}</div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="badge badge-light-success">{historyValue(history.newValue)}</div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </div>

                        </div>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <button type="button" className="btn btn-light" onClick={() => setShowModal(false)}>Close</button>
                </Modal.Footer>
                {loading && <Loader></Loader>}
            </Modal>
        )
    }

    return (
        <>

            <PageTitle breadcrumbs={usersBreadcrumbs} >{intl.formatMessage({ id: 'LEAD.LIST.BREADCRUMB' })}</PageTitle>

            <Card>
                <CardHeader>
                    <CardTitle>
                        <h3>{intl.formatMessage({ id: 'LEAD.LIST.TITLE' })}</h3>
                    </CardTitle>
                    <div className="card-toolbar">
                        <ul className="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0">
                            {
                                campaigns && campaigns.map((campaign, index) => {
                                    return (
                                        <li key={`leads_campaigns_${index}`} className="nav-item" style={{ marginLeft: "20px" }} role="presentation">
                                            <a className={`nav-link ${index == 0 ? 'active' : ''}`} data-bs-toggle="tab" href={`#kt_tab_pane`} onClick={() => onTabClick(index, campaign.campaignType)}>{campaign.name}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </CardHeader>

                <CardBody>
                    <div className="tab-content" id="myTabContent">
                        <div className="d-flex align-items-center position-relative mb-5">
                            <i className="ki-duotone ki-magnifier fs-1 position-absolute ms-6">
                                <span className="path1"></span>
                                <span className="path2"></span>
                            </i>
                            <input type="text" data-kt-user-table-filter="search" className="form-control form-control-solid w-250px ps-14" placeholder={intl.formatMessage({ id: 'GENERAL.SEARCH' })} onChange={(e) => onSearch(e.target.value)} />
                        </div>

                        <div className="tab-pane fade show active " id="kt_tab_pane" role="tabpanel">
                            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                                <div style={{ whiteSpace: "nowrap", overflowX: "auto", display: "flex", alignItems: "stretch" }}>
                                    {
                                        steps && steps.map(([key, value], index) => {
                                            var items = filteredList.filter(s => s.status == key);
                                            return (
                                                <Droppable id={key} data={key} key={`leads_steps_${index}`} className='' style={{ display: "inline-block", float: "none", minWidth: "330px", margin: "0 5px", padding: "0 10px", flex: "1" }}>
                                                    <Card>
                                                        <CardHeader className={headerColorClass(key)}>
                                                            <CardTitle>{value} ({items.length})</CardTitle>
                                                        </CardHeader>
                                                        <CardBody className='bg-light hoverable'>

                                                            {
                                                                items && items.map((item, index) => {

                                                                    var assignedUser = item.assignedUser;

                                                                    return (
                                                                        <Draggable id={item.id} key={`lead_steps_item_${index}`} data={item} className='row mb-5'>
                                                                            <Card className='card-p-0 card-stretch-33 shadow' style={{ height: "125px" }}>
                                                                                <CardBody className='ribbon ribbon-top  mb-3'>
                                                                                    {renderRibbon(item)}
                                                                                    <Row>
                                                                                        <Col>
                                                                                            <button onClick={() => leadModal(item)} className='btn btn-link btn-color-info btn-active-color-info fw-bold'>
                                                                                                {item.id} - {item.title}
                                                                                            </button>
                                                                                        </Col>
                                                                                    </Row>

                                                                                    <Row style={{ alignItems: "center" }} >
                                                                                        <Col>
                                                                                            <span className="badge badge-light">
                                                                                                <i className="ki-duotone ki-time">
                                                                                                    <span className="path1"></span>
                                                                                                    <span className="path2"></span>
                                                                                                </i>
                                                                                                <Moment>{item.leadCreatedOn}</Moment>
                                                                                            </span>
                                                                                        </Col>
                                                                                        <Col className='text-center'>
                                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden" >
                                                                                                <div className="symbol-label" style={{ display: assignedUser ? "" : "table-row" }}><img src={assignedUser && assignedUser.avatarLink} alt={assignedUser && assignedUser.name} className="w-100" /></div>
                                                                                            </div>
                                                                                        </Col>
                                                                                    </Row>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Draggable>
                                                                    )
                                                                })
                                                            }
                                                            <div className='text-center'>
                                                                {items.length > 9 && items.length != items.filter(s => s.show).length ? <p className='btn btn-link' onClick={() => onSeeAllClick(key)}>See All</p> : null}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Droppable>
                                            )
                                        })
                                    }
                                </div>
                            </DndContext>
                        </div>
                    </div>


                </CardBody>
            </Card >
            {renderModal()}
            {loading && <Loader></Loader>}
        </>
    );
};

const mapStateToProps = state => {
    return {
        leads: state.Lead.leads,
        lead: state.Lead.lead,
        campaigns: state.Lead.campaigns,
        users: state.Lead.users,
        historyList: state.Lead.historyList,
        loading: state.Lead.loading,
        success: state.Lead.success,
        error: state.Lead.error
    };
};

const mapActionsToProps = {
    clearState, getCampaigns, getLeads, getLead, patchLead, getUserList, getHistory, postComment
}

export default connect(mapStateToProps, mapActionsToProps)(Leads)