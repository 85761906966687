import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { useIntl } from 'react-intl'
import { useAuth } from '../../../app/modules/auth'
import { PageTitle } from '../../../_metronic/layout/core'
import { Loader, KTIcon, enumPolicies } from '../../../_metronic/helpers'
import { PRIMARY_COLOR } from '../../../_metronic/helpers/colorConstant';
import { Row, Col, CardBody, Card, CardHeader, CardTitle } from 'reactstrap';
import Swal from 'sweetalert2'
import Flatpickr from 'react-flatpickr'
import moment from 'moment';
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'

import "flatpickr/dist/themes/light.css";

import { clearState, getCart, getCartSummary, deleteItem, deleteCart, postOrder, getPaymentMethods } from './checkoutActions';
import types from './checkoutConstants'
import { getUserList } from '../user-management/userActions';


const CheckoutSummary = (props) => {
    //inputs from url
    const intl = useIntl();
    const { currentUser } = useAuth();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);
    const [date, setDate] = useState();
    const [reference, setReference] = useState();
    const [dealerId, setDealerId] = useState();

    //inputs from redux
    const {
        cart,
        cartSummary,
        success,
        userList,
        loading
    } = props;

    //actions
    const {
        clearState,
        getCart,
        deleteItem,
        getCartSummary,
        deleteCart,
        postOrder,
        getUserList
    } = props;

    useEffect(() => {
        getCart();
        getCartSummary(dealerId == '' || dealerId == 'undefined' ? null : dealerId);

        if (currentUser && currentUser.policyIds && currentUser.policyIds.some(policy => policy === enumPolicies.OrderAdmin)) {
            getUserList('', 3, true, true);
        }
        getPaymentMethods();
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (cart && cart.data) {
            var detail = JSON.parse(cart.data);
            if (detail && detail.orderDetailsData) {
                var x = moment(detail.orderDetailsData.wishedDeliveryDate).toDate();
                setDate(x)
                setReference(detail.orderDetailsData.reference)
                setDealerId(detail.orderDetailsData.userId)
                getCartSummary(dealerId == '' || dealerId == 'undefined' ? null : dealerId);
            }
        }
    }, [cart])


    useEffect(() => {
        if (success == types.POST_ORDER_SUCCESS) {
            navigate(`/checkout-management/pay`)
        }
    }, [success])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'CHECKOUTSUMMARY.BREADCRUMB.TITLE' }),
            path: '/checkout-management/checkout',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    const onDealerIdChange = (value) => {
        setDealerId(value);
        getCartSummary(value == '' || value == 'undefined' ? null : value);
    }

    const onRemoveButtonClicked = (id) => {

        var sentence = intl.formatMessage({ id: 'CHECKOUTSUMMARY.QUESTION.DELETEALLITEMS' });
        if (id)
            sentence = intl.formatMessage({ id: 'CHECKOUTSUMMARY.QUESTION.DELETEITEM' });

        Swal.fire({
            title: intl.formatMessage({ id: 'CHECKOUTSUMMARY.QUESTION.SURE' }),
            text: sentence,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: PRIMARY_COLOR,
            cancelButtonColor: "#dbdfe9",
            confirmButtonText: intl.formatMessage({ id: 'CHECKOUTSUMMARY.ANSWER.YESDELETEIT' })
        }).then((result) => {
            if (result.isConfirmed) {
                if (id)
                    deleteItem(id)
                else
                    deleteCart()

                Swal.fire({
                    title: intl.formatMessage({ id: 'CHECKOUTSUMMARY.ANSWER.DELETED' }),
                    text: intl.formatMessage({ id: 'CHECKOUTSUMMARY.ANSWER.HASBEENDELETED' }),
                    icon: "success",
                    confirmButtonColor: PRIMARY_COLOR,
                });
            }
        });
    }

    const onNextClick = () => {
        if (!date) {
            Swal.fire({
                title: "",
                text: intl.formatMessage({ id: 'CHECKOUTSUMMARY.ANSWER.SELECTDATE' }),
                icon: "warning",
                confirmButtonColor: PRIMARY_COLOR,
                confirmButtonText: "Ok"
            })
        }
        else {
            postOrder({
                WishedDeliveryDate: moment(date).format('YYYY-MM-DD'),
                Reference: reference,
                UserId: dealerId == '' ? null : dealerId,
            })
        }
    }

    const renderSummary = () => {

        var count = cartSummary && cartSummary.items && cartSummary.items.length;
        if (count == 0)
            return;

        return (
            <>
                <Card>
                    <CardHeader className="py-3 d-flex bg-primary">
                        <CardTitle>
                            <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.CARTITEMS' })}</h4>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <div className="table-responsive">
                            <table className="table table-rounded table-striped border gy-7 gs-7">
                                <thead>
                                    <tr className="fw-bolder fs-5 text-gray-800 border-bottom border-gray-2000">
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.NAME' })}</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.DETAIL' })}</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.PRICE' })}</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.QUANTITY' })}</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.TOTALPRICE' })}</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.VAT' })} %</th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.VAT' })} </th>
                                        <th>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.ACTION' })}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        cartSummary && cartSummary.items && cartSummary.items.map((item, index) => {
                                            var detail = JSON.parse(item.descriptionJson);
                                            return (
                                                <tr key={`cartsummary_index_${index}`} className='py-5 fw-semibold vertical-middle border-bottom border-gray-300 fs-6'>
                                                    <td width={"15%"}>{item.name}</td>
                                                    <td width={"25%"}>
                                                        {detail.Width ? <p>{intl.formatMessage({ id: "ORDER.FIELD.WIDTH" })}: {detail.Width}</p> : null}
                                                        {detail.Depth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.DEPTH" })}: {detail.Depth}</p> : null}
                                                        {detail.Height ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HEIGHT" })}: {detail.Height}</p> : null}
                                                        {detail.FrameColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRAMECOLOR" })}: {detail.FrameColorName}</p> : null}
                                                        {detail.OtherFrameColor ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGRAMECOLOR" })}: {detail.OtherFrameColor}</p> : null}
                                                        {detail.Length ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LENGTH" })}: {detail.Length}</p> : null}
                                                        {detail.FrontHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.FRONTHEIGHT" })}: {detail.FrontHeight}</p> : null}
                                                        {detail.RearHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.REARHEIGHT" })}: {detail.RearHeight}</p> : null}
                                                        {detail.OrderTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ORDERTYPE" })}: {detail.OrderTypeName}</p> : null}
                                                        {detail.StandTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.STANDTYPE" })}: {detail.StandTypeName}</p> : null}
                                                        {detail.RoofTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.ROOFTYPE" })}: {detail.RoofTypeName}, {detail.MaterialColorName}</p> : null}
                                                        {detail.ModelTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MODELTYPE" })}: {detail.ModelTypeName}</p> : null}
                                                        {detail.LedTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDTYPE" })}: {detail.LedTypeName}</p> : null}
                                                        {detail.LedColorName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.LEDCOLOR" })}: {detail.LedColorName}</p> : null}
                                                        {detail.NrOfLedPerColumn ? <p>{intl.formatMessage({ id: "ORDER.FIELD.NROFLED" })}: {detail.NrOfLedPerColumn}</p> : null}
                                                        {detail.MaterialTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.MATERIALTYPE" })}: {detail.MaterialTypeName}</p> : null}
                                                        {detail.SideName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.SIDE" })}: {detail.SideName}</p> : null}
                                                        {detail.RailSetTypeName ? <p>{intl.formatMessage({ id: "ORDER.FIELD.RAILSET" })}: {detail.RailSetTypeName}</p> : null}
                                                        {detail.IsMeenemerAdded ? <p>{intl.formatMessage({ id: "ORDER.FIELD.HASMEENEMER" })}: {detail.IsMeenemerAdded ? "Yes" : "No"}</p> : null}
                                                        {detail.GlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSWIDTH" })}: {detail.GlassWidth}</p> : null}
                                                        {detail.OtherGlassWidth ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSWIDTH" })}: {detail.OtherGlassWidth}</p> : null}
                                                        {detail.GlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.GLASSHEIGHT" })}: {detail.GlassHeight}</p> : null}
                                                        {detail.OtherGlassHeight ? <p>{intl.formatMessage({ id: "ORDER.FIELD.OTHERGLASSHEIGHT" })}: {detail.OtherGlassHeight}</p> : null}
                                                    </td>
                                                    <td width={"10%"}>€ {item.price}</td>
                                                    <td width={"10%"}>{item.quantity}</td>
                                                    <td width={"10%"}>€ {item.totalPrice}</td>
                                                    <td width={"10%"}> {item.vat}%</td>
                                                    <td width={"10%"}>€ {item.vatPrice}</td>
                                                    <td width={"10%"}>
                                                        <button className='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary' onClick={() => onRemoveButtonClicked(item.uuid)}>
                                                            <i className="ki-duotone ki-cross-square fs-1"> <span className="path1"></span> <span className="path2"></span></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr className="py-5 border-bottom vertical-middle  fs-4 vertical-middle">
                                        <td colSpan={3}></td>
                                        <td colSpan={3}>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.SUBTOTALEXVAT' })} <br></br> {intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.VAT' })} </td>
                                        <td colSpan={2}>
                                            € {cartSummary && cartSummary.items && cartSummary.items.map(s => s.subPrice).reduce((a, b) => a + b, 0).toFixed(2)}
                                            <br></br>
                                            € {cartSummary && cartSummary.items && cartSummary.items.map(s => s.vatPrice).reduce((a, b) => a + b, 0).toFixed(2)}
                                        </td>
                                    </tr>

                                    <tr className="py-5 fw-bold  border-bottom vertical-middle border-gray-500 fs-3 vertical-middle">
                                        <td colSpan={3}></td>
                                        <td colSpan={3}>Total</td>
                                        <td>€ {cartSummary && cartSummary.items && cartSummary.items.map(s => s.totalPrice).reduce((a, b) => a + b, 0)}</td>
                                        <td >
                                            <button className='btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary' onClick={() => onRemoveButtonClicked()}>
                                                <i class="ki-duotone ki-trash fs-2x">
                                                    <span class="path1"></span>
                                                    <span class="path2"></span>
                                                    <span class="path3"></span>
                                                    <span class="path4"></span>
                                                    <span class="path5"></span>
                                                </i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </CardBody>
                </Card>
            </>
        )
    }

    const renderDate = () => {

        var count = cartSummary && cartSummary.items && cartSummary.items.length;
        if (count == 0)
            return;

        return (
            <>
                <Card>
                    <CardHeader className="py-3 d-flex bg-primary">
                        <CardTitle>
                            <h4 className="fw-bold mb-0 ms-4 text-light-success">{intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.DELIVERY' })}</h4>
                        </CardTitle>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={6}>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-7 fw-bolder mb-3'>
                                        Date
                                    </label>
                                    <Flatpickr
                                        onChange={([date]) => {
                                            //var x = moment(date).format('YYYY-MM-DD');
                                            setDate(date);
                                        }}
                                        options={{
                                            dateFormat: "d-m-Y",
                                            minDate: "today"
                                        }}
                                        value={date}
                                        className='form-control form-control-solid'
                                        placeholder='Select Date'
                                    />
                                </div>

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <div className='fv-row mb-5'>
                                    <label className='form-label fs-7 fw-bolder mb-3'>
                                        {intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.REFERENCE' })}
                                    </label>
                                    <input type='text' className='form-control' value={reference} onChange={(e) => setReference(e.target.value)} rows={4} />
                                </div>
                            </Col>

                            <PolicyChecker policies={[enumPolicies.OrderAdmin]}>
                                <Col>
                                    <div className='fv-row mb-5'>
                                        <label className='form-label fs-7 fw-bolder mb-3'>
                                            {intl.formatMessage({ id: 'CHECKOUTSUMMARY.FIELD.DEALER' })}
                                        </label>
                                        <select className='form-control form-select' onChange={(e) => onDealerIdChange(e.target.value)} value={dealerId}>
                                            <option value=''>{intl.formatMessage({ id: 'GENERAL.SELECT' })}</option>
                                            {userList && userList.map((item, index) => {
                                                return (
                                                    <option key={`dropdown_dealers_${index}`} value={item.id} >{item.name} {item.surname}</option>
                                                )
                                            })}
                                        </select>
                                    </div>
                                </Col>
                            </PolicyChecker>

                        </Row>

                    </CardBody>
                </Card>
            </>
        )
    }

    const renderNext = () => {

        var count = cartSummary && cartSummary.items && cartSummary.items.length;
        if (count > 0) {
            return (
                <Row>
                    <Col md={12}>
                        <button style={{ width: "100%" }} onClick={() => onNextClick()} type='submit' className='btn btn-primary mt-10'><span className='fs-2'>{intl.formatMessage({ id: 'GENERAL.NEXT' })} </span> <KTIcon iconName='arrow-right fs-2x mb-1'></KTIcon> </button>
                    </Col>
                </Row>
            )
        }
    }

    const renderEmpty = () => {

        var count = cartSummary && cartSummary.items && cartSummary.items.length;
        if (count > 0)
            return;

        return (
            <>
                <Card>
                    <CardBody className='text-center'>
                        <i class="bi bi-cart fs-5hx text-primary"></i>
                        <Row className='mt-10'></Row>
                        <h1>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.NO.PRODUCT.IN.CART' })}</h1>
                        <Row>
                            <Col md={4}>
                            </Col>
                            <Col md={4}>
                                <button onClick={() => navigate("/checkout-management/checkout")} type='submit' className='btn btn-primary mt-10'><span className='fs-2'>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.GIVE.ORDER' })} </span></button>
                            </Col>
                            <Col md={4}>
                            </Col>
                        </Row>

                    </CardBody>
                </Card>
            </>
        )
    }


    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'CHECKOUTSUMMARY.BREADCRUMB.DESCRIPTION' })}</PageTitle>
            {renderEmpty()}
            <Row className='mt-10'></Row>
            {renderSummary()}
            <Row className='mt-10'></Row>
            {renderDate()}
            <Row className='mt-10'></Row>
            {renderNext()}


            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        cart: state.Checkout.cart,
        cartSummary: state.Checkout.cartSummary,
        userList: state.User.userList,
        loading: state.Checkout.loading,
        success: state.Checkout.success
    };
};

const mapActionsToProps = {
    clearState, getCart, getCartSummary, deleteItem, deleteCart, postOrder, getUserList
}

export default connect(mapStateToProps, mapActionsToProps)(CheckoutSummary)