import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, Link } from "react-router-dom";
import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { Loader, enumPolicies, KTIcon } from '../../../_metronic/helpers'
import Moment from 'react-moment'
import PolicyChecker from '../../../_metronic/helpers/components/PolicyChecker'
import { MenuComponent } from '../../../_metronic/assets/ts/components'

import "flatpickr/dist/themes/light.css";

import { clearState, getInvoices } from './invoiceActions';


const Invoices = (props) => {
    //inputs from url
    const intl = useIntl();
    const navigate = useNavigate();
    const [inLoading, setInloading] = useState(false);
    MenuComponent.reinitialization();

    const [keyword, setKeyword] = useState(null);

    //inputs from redux
    const {
        invoices,
        success,
        loading
    } = props;

    //actions
    const {
        clearState,
        getInvoices
    } = props;

    useEffect(() => {
        getInvoices({ state: 0, keyword: '' })
        return () => {
            clearState()
        }
    }, []);

    useEffect(() => {
        if (success) {
        }
    }, [success])

    useEffect(() => {
        MenuComponent.reinitialization();
    }, [invoices])

    const usersBreadcrumbs = [
        {
            title: intl.formatMessage({ id: 'INVOICES.BREADCRUMB.TITLE' }),
            path: '/order-management/invoices',
            isSeparator: false,
            isActive: false,
        },
        {
            isSeparator: true,
            isActive: false,
        }
    ]

    useEffect(() => {
        if (keyword != undefined || keyword != null) {
            const timeOutId = setTimeout(() => getInvoices({ keyword: keyword }), 500);
            return () => clearTimeout(timeOutId);
        }
    }, [keyword]);

    const onTabClick = (tab) => {
        getInvoices({
            keyword: '',
            state: tab
        })
    }

    const getColors = (status, name) => {
        switch (status) {
            case 1:
                return <span className="badge badge-light-warning">{name}</span>
            case 2:
                return <span className="badge badge-light-success">{name}</span>
            case 3:
                return <span className="badge badge-light-danger">{name}</span>
            case 4:
                return <span className="badge badge-light-dark">{name}</span>
            default:
                return <span className="badge badge-light-primary">{name}</span>
        }
    }

    const onReverse = (id) => {

    }

    return (
        <>
            <PageTitle breadcrumbs={usersBreadcrumbs}>{intl.formatMessage({ id: 'INVOICES.BREADCRUMB.DESCRIPTION' })}</PageTitle>
            <div id="kt_app_content" className="app-content flex-column-fluid">
                <div id="kt_app_content_container" className="app-container container-xxl">
                    <div className="card card-flush  ">
                        <div className="card-header align-items-center py-5 gap-2 gap-md-5">
                            <div className="card-title">
                                <div className="d-flex align-items-center position-relative my-1">
                                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-4">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </i>
                                    <input type="text" data-kt-ecommerce-order-filter="search" className="form-control form-control-solid w-250px ps-12" placeholder={intl.formatMessage({ id: 'INVOICES.SEARCH' })} onChange={(e) => setKeyword(e.target.value)} />
                                </div>
                            </div>
                            <div className="card-toolbar">
                                <PolicyChecker policies={[enumPolicies.InvoiceEdit, enumPolicies.InvoiceWrite]}>
                                    <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
                                        <Link to={`/order-management/invoices/0`} type="button" className="btn btn-success"><i className="ki-duotone ki-plus fs-2"></i>{intl.formatMessage({ id: 'INVOICES.CREATE' })}</Link>
                                    </div>
                                </PolicyChecker>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="">
                                <ul className="nav nav-tabs nav-line-tabs  fs-5 nav-line-tabs-2x">
                                    <li className="nav-item active ">
                                        <a className="nav-link text-active-primary active" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoices" onClick={() => onTabClick(0)}>{intl.formatMessage({ id: "INVOICES.TAB.ALL" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoices" onClick={() => onTabClick(1)}>{intl.formatMessage({ id: "INVOICES.TAB.OPEN" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoices" onClick={() => onTabClick(2)}>{intl.formatMessage({ id: "INVOICES.TAB.PAID" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoices" onClick={() => onTabClick(3)}>{intl.formatMessage({ id: "INVOICES.TAB.EXPIRED" })}</a>
                                    </li>
                                    <li className="nav-item ">
                                        <a className="nav-link text-active-primary" data-bs-toggle="tab" href="#kt_ecommerce_sales_invoices" onClick={() => onTabClick(4)}>{intl.formatMessage({ id: "INVOICES.TAB.CONCEPT" })}</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="kt_ecommerce_sales_invoices" role="tab-panel">
                                        <div className="table-responsive">
                                            <table className="table align-middle table-row-dashed fs-6 gy-5 mb-0" style={{ minWidth: "600px" }}>
                                                <thead>
                                                    <tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                        <th>{intl.formatMessage({ id: "INVOICES.FIELD.NUMBER" })}</th>
                                                        <th>{intl.formatMessage({ id: "INVOICES.FIELD.DATE" })}</th>
                                                        <th>{intl.formatMessage({ id: "INVOICES.FIELD.COMPANY_NAME" })}</th>
                                                        <th>{intl.formatMessage({ id: "INVOICES.FIELD.AMOUNT" })}</th>
                                                        <th>{intl.formatMessage({ id: "INVOICES.FIELD.STATUS" })}</th>
                                                        <PolicyChecker policies={[enumPolicies.InvoiceEdit, enumPolicies.InvoiceWrite]}>
                                                            <th>{intl.formatMessage({ id: "INVOICES.ACTIONS" })}</th>
                                                        </PolicyChecker>
                                                    </tr>
                                                </thead>
                                                <tbody className="fw-semibold text-gray-600">
                                                    {invoices && invoices.map((item) => {
                                                        return (
                                                            <tr key={`invoices_${item.id}`}>
                                                                <td>
                                                                    <Link to={`/order-management/invoices/${item.id}?tab=1`} >{item.invoiceNr ? item.invoiceNr : '-'}</Link>
                                                                </td>
                                                                <td><Moment format='ddd DD MMM'>{item.createdOn}</Moment></td>
                                                                <td>{item.companyDetails && item.companyDetails.companyName}</td>
                                                                <td>{item.totalPrice}</td>
                                                                <td>{getColors(item.invoiceState, item.invoiceStateName)}</td>
                                                                <PolicyChecker policies={[enumPolicies.InvoiceEdit, enumPolicies.InvoiceWrite]}>
                                                                    <td>
                                                                        <a className='btn btn-light btn-active-light-primary btn-sm ' data-kt-menu-trigger="{default: 'click'}" data-kt-menu-placement='bottom-end'> {intl.formatMessage({ id: 'INVOICES.ACTIONS' })}
                                                                            <KTIcon iconName='down' className='fs-5 m-0' />
                                                                        </a>
                                                                        <div className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4' data-kt-menu='true'>
                                                                            <div className='menu-item px-3'>
                                                                                <Link to={`/order-management/invoices/${item.id}?tab=2`} className='menu-link px-3'> {intl.formatMessage({ id: 'INVOICES.ACTIONS.EDIT' })} </Link>
                                                                            </div>
                                                                            <div className='menu-item px-3'>
                                                                                <a className='menu-link px-3' onClick={() => onReverse(item.id)}> {intl.formatMessage({ id: 'INVOICES.ACTIONS.REVERSE' })} </a>
                                                                            </div>
                                                                        </div>
                                                                    </td>
                                                                </PolicyChecker>

                                                            </tr>
                                                        )
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {(loading || inLoading) && <Loader />}
        </>
    );
};

const mapStateToProps = state => {
    return {
        invoices: state.Invoice.invoices,
        loading: state.Invoice.loading,
        success: state.Invoice.success
    };
};

const mapActionsToProps = {
    clearState, getInvoices
}

export default connect(mapStateToProps, mapActionsToProps)(Invoices)